import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms'
import { Sort } from '@angular/material/sort'
import { Navigate } from '@ngxs/router-plugin'
import { Store } from '@ngxs/store'
import { HttpErrorResponse } from '@angular/common/http'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs'
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators'
import moment from 'moment'
import { TranslateService } from '@ngx-translate/core'
import { parsePhoneNumber } from 'libphonenumber-js'
import { ChannelService, ChatClientService, DefaultStreamChatGenerics, StreamChatModule } from 'stream-chat-angular'
import { Channel } from 'stream-chat'
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort'
import { MatMenuModule } from '@angular/material/menu'
import { MatRippleModule } from '@angular/material/core'

import { SharedModule } from '../shared.module'
import { TableComponent } from '../table/table.component'
import { ReminderHistoryModule } from '../../../module/dashboard/tabs/economy-tab/reminder-history/reminder-history.module'
import { NewReminderComponent } from '../../../module/dashboard/tabs/economy-tab/new-reminder/new-reminder.component'
import { EditUserPaymentComponent } from '../../../module/dashboard/tabs/economy-tab/edit-user-payment/edit-user-payment.component'
import { NewInvoiceModule } from '../../../module/dashboard/tabs/economy-tab/new-invoice/new-invoice.module'
import { InvoiceHistoryModule } from '../../../module/dashboard/tabs/economy-tab/invoice-history/invoice-history.module'
import { PhoneNumberModule } from '../phone-number/phone-number.module'
import { EditDugnadOrderComponent } from '../../../module/dashboard/tabs/economy-tab/edit-dugnad-order/edit-dugnad-order.component'
import { NewDugnadReminderComponent } from '../../../module/dashboard/tabs/economy-tab/new-dugnad-reminder/new-dugnad-reminder.component'
import { DugnadReminderHistoryComponent } from '../../../module/dashboard/tabs/economy-tab/dugnad-reminder-history/dugnad-reminder-history.component'
import { UsersService, OrgService, EconomyTabService } from '../../../core/api'
import { DateFormat } from '../../../core/enums/global/date-format'
import { JerseyNumberGroup } from './interfaces/jersey-number-group'
import { MainTabMode } from './enums/main-tab-mode'
import { UserProfileTab } from './enums/user-profile-tab'
import { AdminTitle } from '../../../core/interfaces/user/adminTitle'
import { Gender } from '../../../core/enums/user/gender'
import { SetUser, SetUserPicture } from '../../../module/dashboard/states/dashboard.actions'
import { DashboardState } from '../../../module/dashboard/states/dashboard.state'
import { SnackbarService } from '../../components/snackbar/snackbar.service'
import { UpdateUserProfile, ToggleUserProfile, UpdateUserTitles } from './state/user-profile.actions'
import { UserProfileState } from './state/user-profile.state'
import { UserGroup, UserResponse } from '../../../core/interfaces/user/user-response'
import { GroupSelectorState } from '../group-selector/states/group-selector.state'
import { Table } from '../../../core/classes/table/table'
import { IndividualPaymentResponse } from '../../../module/dashboard/tabs/economy-tab/individual-payments/interfaces/individual-payment-response'
import { Constants } from '../../../constants/constants'
import { Columns } from '../../../core/enums/table/columns'
import { PaymentTypes } from '../../../core/enums/economy/payment-types'
import { PaidStatus } from '../../../module/dashboard/tabs/economy-tab/enums/paid-status'
import { PaidStatusFilterValues } from '../../../module/dashboard/tabs/economy-tab/enums/paid-status-filter-values'
import { FilterNames } from '../../../module/dashboard/tabs/economy-tab/enums/filter-names'
import { UserTitle } from './interfaces/user-title-response'
import { UserTitleFormatted } from './interfaces/user-title-formatted'
import { MediaPrivacyPermission } from '../../../core/enums/user/media-privacy-permission'
import { Organization } from '../../../core/interfaces/organization/organization'
import { GroupResponse } from '../../../core/interfaces/organization/group-response'
import { SelectedGroup } from '../../../core/interfaces/organization/selected-group'
import { CurrentUserResponse } from '../../../core/interfaces/user/current-user-response'
import { UserSearchResponse } from '../../../core/interfaces/user/user-search-response'
import { OrderStatus } from '../../../module/dashboard/tabs/economy-tab/interfaces/order-status'
import { EconomyTabState, UserPermissions } from '../../../module/dashboard/tabs/economy-tab/states/economy-tab.state'
import { Modal } from '../../../core/classes/global/modal'
import { userProfileAnimations } from './animations/user-profile-animations'
import { Popup } from '../../../core/classes/global/popup'
import { CustomFieldResponse } from './interfaces/custom-field-response'
import { FloatComponent } from '../../components/float/float.component'
import { PhoneNumberInput } from '../phone-number/interfaces/phone-number-input'
import { InvitationReminderDataResponse } from './interfaces/invitation-reminder-data-response'
import { PhonePipe } from '../../pipes/phone.pipe'

interface UserProfileForm {
  firstName: FormControl<string>
  lastName: FormControl<string>
  email: FormControl<string>
  phoneCountryCode: FormControl<string | null>
  phoneMobile: FormControl<string | null>
  dateOfBirth: FormControl<string | null>
  gender: FormControl<Gender>
  mediaPrivacyPermission: FormControl<MediaPrivacyPermission>
  streetAddress?: FormControl<string>
  postalCode?: FormControl<string>
  city?: FormControl<string>
  customFields?: FormGroup<{
    [fieldId: number]: FormControl<any>
  }>
}

interface ResendInvitationForm {
  email: FormControl<string>
}

// TODO: move out of /shared/modules
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  host: { '[@userProfileAnimation]': '' },
  animations: userProfileAnimations,
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatRippleModule,
    SharedModule,
    ReminderHistoryModule,
    TableComponent,
    NewReminderComponent,
    NewInvoiceModule,
    InvoiceHistoryModule,
    EditUserPaymentComponent,
    PhoneNumberModule,
    NewDugnadReminderComponent,
    DugnadReminderHistoryComponent,
    EditDugnadOrderComponent,
    StreamChatModule,
    PhonePipe
  ]
})
export class UserProfileComponent extends FloatComponent implements OnInit, OnDestroy {
  @SelectSnapshot(DashboardState.organization) organization: Organization
  @SelectSnapshot(DashboardState.rootAdminGroups) rootAdminGroups: GroupResponse[]
  @SelectSnapshot(DashboardState.user) currentUser: CurrentUserResponse
  @SelectSnapshot(DashboardState.isTopLevelAdmin) isTopLevelAdmin: boolean
  @SelectSnapshot(EconomyTabState.userEconomyPermissions) userEconomyPermissions: UserPermissions
  @SelectSnapshot(GroupSelectorState.selectedGroup) selectedGroup: SelectedGroup
  @SelectSnapshot(UserProfileState.userId) userId: number
  @SelectSnapshot(UserProfileState.occurrenceGroup) occurrenceGroup: { id: number }
  @SelectSnapshot(UserProfileState.tabToOpen) tabToOpen: UserProfileTab

  loading: boolean = false
  mobileView: 'menu' | 'details' = 'menu'
  user: UserResponse | null = null
  // TODO: add type when role_id and id property names are unified on backend
  userTitles: UserTitleFormatted[] | null = null
  allTitles: AdminTitle[] = []
  smsSender: string | null = null
  lastInvitationDatetime: string | null = null
  customFields: CustomFieldResponse[] = []
  customFieldsGrouped: {
    [groupId: number]: {
      groupName: string
      parentGroupName: string | null
      customFields: CustomFieldResponse[]
    }
  } = {}
  userProfileForm: FormGroup<UserProfileForm>
  resendInvitationForm: FormGroup<ResendInvitationForm>
  newTitleControl: FormControl<AdminTitle | null> = new FormControl(null)
  activeTab: UserProfileTab = UserProfileTab.Info
  mainTabMode: MainTabMode = MainTabMode.Overview
  addGuardianPopup: Popup = new Popup()
  // TODO: separate add guardian into own component?
  isSearchingByName: boolean
  searchByNameControl: FormControl<string> = new FormControl('', { nonNullable: true })
  searchByEmailControl: FormControl<string> = new FormControl('', { nonNullable: true })
  searchByNameResults: UserSearchResponse[]
  searchByEmailResults: UserSearchResponse[]
  selectedUserByName: UserSearchResponse | null = null
  selectedUserByEmail: UserSearchResponse | null = null
  addGuardianSearchLoading: boolean = false
  userList: UserSearchResponse[]
  noUserFoundByName: boolean = false
  noUserFoundByEmail: boolean = false
  contactInfoRequired: boolean = false
  today: string = moment().format(DateFormat.YYYYMMDD)
  jerseyNumberPopup: Popup = new Popup()
  selectedJerseyNumberGroup: JerseyNumberGroup
  paymentsTable: Table<IndividualPaymentResponse> = new Table()
  selectedPayment: IndividualPaymentResponse | null = null
  removeTitleModal: Modal = new Modal()
  titleToRemove: UserTitleFormatted | null = null
  isNewReminderOpen: boolean = false
  isReminderHistoryOpen: boolean = false
  isNewInvoiceOpen: boolean = false
  isInvoiceHistoryOpen: boolean = false
  isEditUserPaymentOpen: boolean = false
  listOfPreviouslyOpenedUsers: number[] = []
  channels: Channel<DefaultStreamChatGenerics>[]

  private dataUpdated$: Subject<void> = new Subject()
  private componentDestroyed$: Subject<void> = new Subject<void>()

  get isSmallScreen(): boolean {
    return window.innerWidth <= 768
  }

  get isPlayer(): boolean {
    return !!this.user?.groups.player.length
  }

  get isGuardian(): boolean {
    return !!this.user?.groups.guardian.length
  }

  get isAdmin(): boolean {
    return !!this.user?.groups.admin.length
  }

  get isCurrentUser() {
    return this.userId === this.currentUser.id
  }

  get DateFormat() {
    return DateFormat
  }

  get MediaPrivacyPermission() {
    return MediaPrivacyPermission
  }

  get UserProfileTab() {
    return UserProfileTab
  }

  get MainTabMode() {
    return MainTabMode
  }

  get Gender() {
    return Gender
  }

  get Response() {
    return Response
  }

  get Columns() {
    return Columns
  }

  get PaymentTypes() {
    return PaymentTypes
  }

  get PaidStatus() {
    return PaidStatus
  }

  get OrderStatus() {
    return OrderStatus
  }

  constructor(
    public elementRef: ElementRef,
    private formBuilder: NonNullableFormBuilder,
    private snackbarService: SnackbarService,
    private usersService: UsersService,
    private orgService: OrgService,
    private translate: TranslateService,
    private store: Store,
    private economyTabService: EconomyTabService,
    private chatClientService: ChatClientService,
    private channelService: ChannelService
  ) {
    super(elementRef)
  }

  ngOnInit() {
    this.initUser(this.userId)

    this.setTableSettings()
    this.paymentsTable.data$ = this.getUnpaidPayments()

    this.initSearchGuardianByName()
    this.initSearchGuardianByEmail()
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.componentDestroyed$.next()
    this.componentDestroyed$.complete()
  }

  private setMainTabMode(mode: MainTabMode): void {
    this.mainTabMode = mode
  }

  private setActiveTab(tab: UserProfileTab): void {
    this.activeTab = tab
    this.setMainTabMode(MainTabMode.Overview)
  }

  // Used in the template only
  openTab(tab: UserProfileTab): void {
    if (this.user) {
      this.setActiveTab(tab)
      this.mobileView = 'details'

      if (tab === UserProfileTab.Chat) {
        this.setChatChannel()
      }
    }
  }

  mobileBack(): void {
    if (this.activeTab === UserProfileTab.Info && this.mainTabMode !== MainTabMode.Overview) {
      this.mainTabMode = MainTabMode.Overview
    } else {
      this.mobileView = 'menu'
    }
  }

  private initResendInvitationForm(): void {
    this.resendInvitationForm = this.formBuilder.group({
      email: [this.user!.email, Validators.email]
    })
  }

  openEditUserDetails(): void {
    this.userProfileForm = this.formBuilder.group({
      firstName: [this.user!.first_name],
      lastName: [this.user!.last_name],
      email: [this.user!.email, Validators.email],
      phoneCountryCode: [
        (this.user!.phone_mobile ? '+' + parsePhoneNumber(this.user!.phone_mobile).countryCallingCode : null) as
          | string
          | null
      ],
      phoneMobile: [
        (this.user!.phone_mobile ? parsePhoneNumber(this.user!.phone_mobile).nationalNumber : null) as string | null
      ],
      dateOfBirth: [{ value: this.user!.date_of_birth, disabled: !this.isTopLevelAdmin }],
      gender: [this.user!.gender],
      mediaPrivacyPermission: [
        this.user!.media_privacy_permission === MediaPrivacyPermission.NotSet ||
        this.user!.media_privacy_permission === MediaPrivacyPermission.NoPermission
          ? MediaPrivacyPermission.NoPermission
          : this.user!.media_privacy_permission
      ]
    })

    if (this.isCurrentUser) {
      this.userProfileForm.addControl('city', new FormControl(this.user!.city, { nonNullable: true }))
      this.userProfileForm.addControl(
        'postalCode',
        new FormControl(this.user!.postal_code, { nonNullable: true, validators: Validators.required })
      )
      this.userProfileForm.addControl(
        'streetAddress',
        new FormControl(this.user!.street_address, { nonNullable: true })
      )

      this.userProfileForm.controls.firstName.setValidators(Validators.required)
      this.userProfileForm.controls.lastName.setValidators(Validators.required)
      this.userProfileForm.controls.dateOfBirth.setValidators(Validators.required)
      this.userProfileForm.controls.gender.setValidators(Validators.required)
    }

    if (this.customFields.length) {
      const customFieldsControls: { [fieldId: number]: FormControl<any> } = {}
      this.customFields.forEach(
        (field) =>
          (customFieldsControls[field.id] = new FormControl(
            {
              value: field.field_data.value,
              disabled: !field.can_edit
            },
            {
              validators: field.is_required
                ? field.type === 'boolean'
                  ? Validators.requiredTrue
                  : Validators.required
                : null
            }
          ))
      )
      this.userProfileForm.addControl('customFields', this.formBuilder.group(customFieldsControls))
    }

    // TODO: check and refactor
    this.contactInfoRequired = !!this.user!.phone_mobile /* || this.userType !== UserTypes.Player */

    this.setMainTabMode(MainTabMode.Edit)
  }

  handlePhoneNumberUpdate({ phoneCode, phoneNumber, isValid }: PhoneNumberInput): void {
    this.userProfileForm.controls.phoneCountryCode.patchValue(phoneCode)
    this.userProfileForm.controls.phoneMobile.patchValue(phoneNumber)
    this.userProfileForm.controls.phoneMobile.setErrors(isValid ? null : { invalidPhoneNumber: true })
  }

  setSearchingByName(isSearchingByName: boolean): void {
    this.isSearchingByName = isSearchingByName
    this.userList = isSearchingByName ? this.searchByNameResults : this.searchByEmailResults
  }

  openAddGuardianPopup() {
    this.addGuardianPopup.open = true

    this.searchByNameResults = []
    this.searchByEmailResults = []

    this.setSearchingByName(true)
  }

  closeAddGuardianPopup(): void {
    this.addGuardianPopup.open = false
    this.searchByNameControl.reset()
    this.searchByEmailControl.reset()
  }

  private setChatChannel(): void {
    const channel = this.chatClientService.chatClient.channel('messaging', {
      members: [this.currentUser.uuid, this.user!.profile_picture!.uuid!]
    })

    this.channelService.deselectActiveChannel()
    this.channelService.channels$
      .pipe(
        // This will get us the current channel list (if list is not yet inited, it will wait for init)
        filter((channels) => !!channels),
        take(1)
      )
      .subscribe(async () => {
        try {
          await channel.watch()
        } catch (error) {
          this.snackbarService.error(error.message)
          throw error
        }
        this.channelService.setAsActiveChannel(channel)
      })
  }

  private initUser(userId: number, options?: { updateUser?: boolean }) {
    this.loading = true
    this.userTitles = null

    // TODO: try to combine all API calls into one observable
    this.getUser(userId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (user) => {
          if (this.tabToOpen) {
            this.setActiveTab(this.tabToOpen)
          }

          if (options?.updateUser) {
            this.setActiveTab(UserProfileTab.Info)
          } else {
            this.listOfPreviouslyOpenedUsers.push(user.id)
          }

          this.initResendInvitationForm()

          const customFieldsAPICall$ = this.getCustomFields().pipe(catchError(() => of(null)))

          const apiCallsToMake: Observable<any>[] = [customFieldsAPICall$]

          if (user.groups.admin.length) {
            const userTitlesAPICall$ = this.getUserTitles().pipe(catchError(() => of(null)))

            const currentGroupForTitle = this.selectedGroup || this.occurrenceGroup || this.rootAdminGroups[0]
            const availableTitlesAPICall$ = this.orgService.getAdminTitles(currentGroupForTitle.id).pipe(
              tap((response) => {
                this.allTitles = response.map((title: AdminTitle) => {
                  title.groupId = currentGroupForTitle.id
                  return title
                })
              }),
              catchError(() => of(null))
            )

            apiCallsToMake.push(userTitlesAPICall$, availableTitlesAPICall$)
          }

          if (user.can_send_invitation) {
            const invitationReminderAPICall$ = this.getInvitationReminderData(user.id).pipe(catchError(() => of(null)))
            apiCallsToMake.push(invitationReminderAPICall$)
          }

          // Combine all necessary API calls to only hide the loader after all of them are done
          forkJoin(apiCallsToMake)
            .pipe(
              takeUntil(this.componentDestroyed$),
              finalize(() => (this.loading = false))
            )
            .subscribe()
        },
        error: () => (this.loading = false)
      })
  }

  private getUser(userId: number): Observable<UserResponse> {
    return this.orgService.getUser(this.organization.id, userId).pipe(tap((response) => (this.user = response)))
  }

  private getInvitationReminderData(userId: number): Observable<InvitationReminderDataResponse> {
    return this.orgService.getInvitationReminderData(userId, this.organization.id).pipe(
      tap((response) => {
        this.smsSender = response.sender_name
        this.lastInvitationDatetime = response.last_invitation_sent
      })
    )
  }

  private getCustomFields(): Observable<CustomFieldResponse[]> {
    return this.orgService.getCustomFields(this.user!.id, this.organization.id).pipe(
      tap((response) => {
        this.customFields = response
        this.customFieldsGrouped = {}

        this.customFields.forEach((field) => {
          if (this.customFieldsGrouped[field.group.id]) {
            this.customFieldsGrouped[field.group.id].customFields.push(field)
          } else {
            this.customFieldsGrouped[field.group.id] = {
              groupName: field.group.name,
              parentGroupName: field.parent_group?.name || null,
              customFields: [field]
            }
          }
        })
      })
    )
  }

  saveProfile() {
    const payload: Partial<UserResponse> = {
      first_name: this.userProfileForm.value.firstName,
      last_name: this.userProfileForm.value.lastName,
      date_of_birth: this.userProfileForm.value.dateOfBirth,
      email: this.userProfileForm.value.email,
      phone_mobile: this.userProfileForm.value.phoneMobile
        ? this.userProfileForm.value.phoneCountryCode + this.userProfileForm.value.phoneMobile
        : '',
      gender: this.userProfileForm.value.gender
    }

    if (this.userProfileForm.controls.city) {
      payload.city = this.userProfileForm.value.city
    }

    if (this.userProfileForm.controls.streetAddress) {
      payload.street_address = this.userProfileForm.value.streetAddress
    }

    if (this.userProfileForm.controls.postalCode) {
      payload.postal_code = this.userProfileForm.value.postalCode
    }

    if (this.userProfileForm.controls.mediaPrivacyPermission.dirty) {
      payload.media_privacy_permission = this.userProfileForm.value.mediaPrivacyPermission
    }

    const updateUserAPICall$ = this.usersService.updateUser(this.user!.id, payload, this.organization.id)
    const getUserAPICall$ = this.orgService
      .getUser(this.organization.id, this.userId)
      .pipe(tap((response) => (this.user = response)))

    const postAPICalls: Observable<any>[] = [updateUserAPICall$]
    const getAPICalls: Observable<any>[] = [getUserAPICall$]

    if (this.customFields.length) {
      const updateCustomFieldsAPICall$ = this.orgService.updateCustomFields(
        this.user!.id,
        this.organization.id,
        this.customFields.map((field) => ({ id: field.id, value: this.userProfileForm.value.customFields![field.id] }))
      )
      const getCustomFieldsAPICall$ = this.getCustomFields()

      postAPICalls.push(updateCustomFieldsAPICall$)
      getAPICalls.push(getCustomFieldsAPICall$)
    }

    this.loading = true
    forkJoin(postAPICalls)
      .pipe(
        tap(() => {
          this.snackbarService.success(this.translate.instant('toaster.user_saved'))
          this.setMainTabMode(MainTabMode.Overview)

          if (this.isCurrentUser) {
            this.usersService.getCurrentUser().subscribe((user) => this.store.dispatch(new SetUser(user)))
          }
        }),
        switchMap(() => forkJoin(getAPICalls)),
        finalize(() => (this.loading = false))
      )
      .subscribe()
  }

  private handleGuardianAdded() {
    this.loading = true
    this.orgService
      .getUser(this.organization.id, this.userId)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((user) => (this.user = user))

    this.store.dispatch(new UpdateUserProfile())

    if (this.isCurrentUser) {
      this.usersService
        .getCurrentUser()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((user) => this.store.dispatch(new SetUser(user)))
    }
  }

  resendInvitation(): void {
    const payload: { email?: string } = {}
    if (this.resendInvitationForm.value.email) {
      payload.email = this.resendInvitationForm.value.email
    }

    this.loading = true
    this.orgService
      .sendInvitation(this.user!.id, this.organization.id, payload)
      .pipe(
        switchMap(() => {
          this.snackbarService.success(this.translate.instant('user_profile.invitation_sent'))

          const apiCallsToMake: Observable<any>[] = [this.getInvitationReminderData(this.user!.id)]

          // Email is updated when it's filled and submitted in the invitation tab.
          if (this.resendInvitationForm.controls.email.dirty) {
            apiCallsToMake.push(this.getUser(this.user!.id))
          }

          return forkJoin(apiCallsToMake)
        }),
        takeUntil(this.componentDestroyed$),
        finalize(() => (this.loading = false))
      )
      .subscribe()
  }

  openUserProfile(userId: number, options?: { openPreviousUser: boolean }): void {
    this.store.dispatch(new ToggleUserProfile(true, userId))
    this.initUser(userId, { updateUser: true })
    this.mobileView = 'menu'

    if (!options?.openPreviousUser) {
      this.listOfPreviouslyOpenedUsers.push(userId)
    }
  }

  closeProfile() {
    this.store.dispatch(new ToggleUserProfile(false))
  }

  openPreviousProfile(): void {
    this.listOfPreviouslyOpenedUsers.pop()
    this.openUserProfile(this.listOfPreviouslyOpenedUsers[this.listOfPreviouslyOpenedUsers.length - 1], {
      openPreviousUser: true
    })
  }

  selectUser(user: UserSearchResponse): void {
    if (this.isSearchingByName) {
      this.selectedUserByName = user
    } else {
      this.selectedUserByEmail = user
    }
  }

  addGuardian(): void {
    const userToAdd = this.isSearchingByName ? this.selectedUserByName : this.selectedUserByEmail
    const isExistingGuardian = !!this.user!.guardians.find((existingGuardian) => existingGuardian.id === userToAdd!.id)
    if (isExistingGuardian) {
      this.snackbarService.error(
        `${userToAdd!.first_name} ${this.translate.instant('toaster.already_registered')} ${this.user!.first_name}`
      )
      return
    }

    this.addGuardianPopup.loading = true
    this.usersService
      .addGuardian(this.user!.id, userToAdd!.id)
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => (this.addGuardianPopup.loading = false))
      )
      .subscribe(() => {
        this.addGuardianPopup.open = false
        this.handleGuardianAdded()
        this.snackbarService.success(
          `${userToAdd!.first_name} ${this.translate.instant('toaster.is_provided')} ${this.user!.first_name}`
        )
      })
  }

  addTitle(): void {
    const isExistingTitle =
      this.userTitles &&
      !!this.userTitles.find(
        (title) => title.id === +this.newTitleControl.value!.id && title.groupId === this.newTitleControl.value!.groupId
      )
    if (isExistingTitle) {
      this.snackbarService.error(
        `${this.user!.first_name} ${this.translate.instant('toaster.has_title')} ${
          this.newTitleControl.value!.title
        } ${this.translate.instant('user_profile.in')} ${this.selectedGroup.name}`
      )
      return
    }

    const currentGroupForTitle = this.selectedGroup || this.occurrenceGroup || this.rootAdminGroups[0]
    this.loading = true
    this.orgService
      .addAdminTitle(currentGroupForTitle.id, this.user!.id, +this.newTitleControl.value!.id)
      .pipe(
        switchMap(() => this.getUserTitles()),
        takeUntil(this.componentDestroyed$),
        finalize(() => (this.loading = false))
      )
      .subscribe(() => {
        this.snackbarService.success(
          this.translate.instant('toast.title_added_success', {
            name: `${this.user!.first_name} ${this.user!.last_name}`,
            title: this.newTitleControl.value!.title,
            group: currentGroupForTitle.name
          })
        )
        this.newTitleControl.reset()
        this.store.dispatch(new UpdateUserTitles())
      })
  }

  openRemoveTitleModal(titleToRemove: UserTitleFormatted): void {
    this.titleToRemove = titleToRemove
    this.removeTitleModal.open = true
  }

  closeRemoveTitleModal(): void {
    this.titleToRemove = null
    this.removeTitleModal.open = false
  }

  removeTitle() {
    this.removeTitleModal.loading = true
    this.orgService
      .deleteAdminTitle(this.titleToRemove!.groupId, this.titleToRemove!.id, this.user!.id)
      .pipe(
        switchMap(() => this.getUserTitles()),
        takeUntil(this.componentDestroyed$),
        finalize(() => (this.removeTitleModal.loading = false))
      )
      .subscribe(() => {
        this.snackbarService.info(
          this.translate.instant('toast.title_removed_success', {
            name: `${this.user!.first_name} ${this.user!.last_name}`,
            title: this.titleToRemove!.title,
            group: this.titleToRemove!.group
          })
        )
        this.closeRemoveTitleModal()
        this.store.dispatch(new UpdateUserTitles())
      })
  }

  updateProfilePicture(url) {
    this.user = { ...this.user!, profile_picture: { ...this.user!.profile_picture, url } }
    if (this.isCurrentUser) {
      this.store.dispatch(new SetUserPicture(url))
    }
  }

  openJerseyNumberPopup(group: UserGroup): void {
    this.selectedJerseyNumberGroup = {
      name: group.name,
      divisionName: group.division_name,
      id: group.id,
      membershipId: group.membership_id,
      number: group.jersey_number || null
    }
    this.jerseyNumberPopup.open = true
  }

  updateJerseyNumber(): void {
    this.jerseyNumberPopup.loading = true
    this.orgService
      .updateJerseyNumber(
        this.selectedJerseyNumberGroup.number,
        this.selectedJerseyNumberGroup.id,
        this.selectedJerseyNumberGroup.membershipId
      )
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => (this.jerseyNumberPopup.loading = false))
      )
      .subscribe((response) => {
        const groupToUpdate: any = this.user!.groups.player.find(
          (group) => group.id === this.selectedJerseyNumberGroup.id
        )
        groupToUpdate.jersey_number = response.jersey_number

        this.jerseyNumberPopup.open = false
        this.store.dispatch(new UpdateUserProfile())
      })
  }

  private getUserTitles(): Observable<UserTitle[]> {
    return this.orgService.getAdminRolesByOrg(this.organization.id, this.user!.id).pipe(
      tap(
        (response) =>
          (this.userTitles = response.map((userRole: UserTitle) => {
            return {
              title: userRole.role.title,
              group: userRole.group.name,
              id: userRole.role.id,
              groupId: userRole.group.id,
              divisionName: userRole.group.division_name
            }
          }))
      )
    )
  }

  private initSearchGuardianByName(): void {
    this.searchByNameControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value) => {
          this.selectedUserByName = null
          const searchQuery = value.trim()
          if (searchQuery) {
            this.addGuardianSearchLoading = true
            return this.orgService
              .userSearch(this.organization.id, { fullNameQuery: searchQuery })
              .pipe(catchError(() => of([])))
          } else {
            return of([])
          }
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((response) => {
        this.addGuardianSearchLoading = false
        this.searchByNameResults = response
        this.userList = response
        this.noUserFoundByName = !!this.searchByNameControl.value.trim() && !response.length
      })
  }

  private initSearchGuardianByEmail(): void {
    this.searchByEmailControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value) => {
          this.selectedUserByEmail = null
          if (value) {
            this.addGuardianSearchLoading = true
            return this.orgService
              .userSearch(this.organization.id, { emailQuery: value })
              .pipe(catchError(() => of([])))
          } else {
            return of([])
          }
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((response) => {
        this.addGuardianSearchLoading = false
        this.searchByEmailResults = response
        this.userList = response
        this.noUserFoundByEmail = !!this.searchByEmailControl.value.trim() && !response.length
      })
  }

  private setTableSettings(): void {
    this.paymentsTable.columns = [
      Columns.PaymentName,
      Columns.Group,
      Columns.Type,
      Columns.NetAmount,
      Columns.DueDate,
      Columns.PaymentStatus,
      Columns.LastReminder,
      Columns.LastInvoice,
      Columns.Comments,
      Columns.Actions
    ]
  }

  getUnpaidPayments(): Observable<IndividualPaymentResponse[]> {
    const resetPagination$ = this.paymentsTable.sorting$.subscribe(() => (this.paymentsTable.page = 1))

    return combineLatest([
      this.paymentsTable.sorting$,
      this.paymentsTable.paging$.pipe(startWith(null)),
      this.dataUpdated$.pipe(startWith(null))
    ]).pipe(
      tap(() => (this.paymentsTable.loading = true)),
      switchMap(([sorting]) => {
        return this.economyTabService
          .getIndividualPayments({
            groupId: this.organization.id,
            filters: {
              [FilterNames.PaidStatus]: [PaidStatusFilterValues.NotPaid],
              [FilterNames.UserId]: this.user!.id
            },
            sorting,
            page: this.paymentsTable.page
          })
          .pipe(
            catchError((e: HttpErrorResponse) => {
              if (e.error.code === Constants.invalidPageError) {
                this.paymentsTable.page -= 1
                this.paymentsTable.paging$.next()
              }
              return of(Constants.getEmptyPagination())
            }),
            map((response) => {
              this.paymentsTable.rowsTotal = response.count
              return response.results
            }),
            tap((payments) => {
              this.paymentsTable.loading = false
              this.paymentsTable.rowsCount = payments.length
            }),
            finalize(() => resetPagination$.unsubscribe())
          )
      })
    )
  }

  onSort(sorting: Sort): void {
    this.paymentsTable.sorting$.next(sorting.direction ? sorting : null)
  }

  openNewReminder(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isNewReminderOpen = true
  }

  closeNewReminder(shouldRefreshTable: boolean): void {
    this.isNewReminderOpen = false
    this.selectedPayment = null

    if (shouldRefreshTable) {
      this.dataUpdated$.next()
    }
  }

  openReminderHistory(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isReminderHistoryOpen = true
  }

  closeReminderHistory(shouldOpenNewReminder: boolean): void {
    this.isReminderHistoryOpen = false

    if (shouldOpenNewReminder) {
      this.isNewReminderOpen = true
    } else {
      this.selectedPayment = null
    }
  }

  openNewInvoice(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isNewInvoiceOpen = true
  }

  closeNewInvoice(shouldRefreshTable: boolean): void {
    this.isNewInvoiceOpen = false
    this.selectedPayment = null

    if (shouldRefreshTable) {
      this.dataUpdated$.next()
    }
  }

  openInvoiceHistory(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isInvoiceHistoryOpen = true
  }

  closeInvoiceHistory(shouldGenerateNewInvoice: boolean): void {
    this.isInvoiceHistoryOpen = false

    if (shouldGenerateNewInvoice) {
      this.isNewInvoiceOpen = true
    } else {
      this.selectedPayment = null
    }
  }

  openEditUserPayment(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isEditUserPaymentOpen = true
  }

  closeEditUserPayment(shouldRefreshTable: boolean): void {
    this.isEditUserPaymentOpen = false
    this.selectedPayment = null

    if (shouldRefreshTable) {
      this.dataUpdated$.next()
    }
  }

  goToIndividualPaymentsPage() {
    this.store.dispatch(new ToggleUserProfile(false))
    this.store.dispatch(
      new Navigate(['dashboard/economy/individual-payments/'], {
        userId: this.user!.id,
        firstName: this.user!.first_name,
        lastName: this.user!.last_name
      })
    )
  }

  @HostListener('mousedown', ['$event.target'])
  handleMouseDown(target): void {
    if (this.elementRef.nativeElement === target) {
      this.closeProfile()
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyDown(): void {
    if (!this.elementRef.nativeElement.querySelectorAll(this.floatElementsSelectors).length) {
      this.closeProfile()
    }
  }
}
