import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core'
import { getValue, RemoteConfig } from '@angular/fire/remote-config'
import { Select } from '@ngxs/store'
import { Observable, Subject, filter, takeUntil, raceWith, timer } from 'rxjs'

import { RemoteConfigKeys } from '../../core/enums/global/remote-config-keys'
import { GlobalState } from '../states/global.state'

@Directive({ selector: '[appFeatureFlag]', standalone: true })
export class FeatureFlagDirective implements OnDestroy {
  @Select(GlobalState.isLoadingRemoteConfig) isLoadingRemoteConfig$: Observable<boolean>

  @Input() set appFeatureFlag(value: RemoteConfigKeys) {
    if (value) {
      this.isLoadingRemoteConfig$
        .pipe(
          filter((loading) => loading === false),
          raceWith(timer(5000)),
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(() => {
          if (getValue(this.remoteConfig, value).asBoolean()) {
            this.viewContainer.createEmbeddedView(this.templateRef)
          }
        })
    }
  }

  private componentDestroyed$: Subject<void> = new Subject<void>()

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private remoteConfig: RemoteConfig
  ) {}

  ngOnDestroy() {
    this.componentDestroyed$.next()
    this.componentDestroyed$.complete()
  }
}
