<div class="ns-center-content-container ns-text">
  <div class="ns-content">
    <img alt="{{ 'img_alt.hoopit_logo' | translate }}" src="/assets/img/logo/HoopitLogo_red.svg" />

    <form [formGroup]="loginForm" class="ns-form ns-light" id="loginForm" data-testid="form" (ngSubmit)="login()">
      <app-phone-number
        [phoneCode]="loginForm.controls.phoneCountryCode.value"
        [phoneNumber]="loginForm.controls.phoneMobile.value"
        (phoneNumberInput)="handlePhoneNumberUpdate($event)"
      ></app-phone-number>

      <mat-form-field>
        <mat-label>{{ 'user_attributes.password' | translate }}</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" />
        <button mat-icon-button matSuffix type="button" (click)="togglePasswordVisibility()">
          <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>
    </form>

    <app-button form="loginForm" type="submit" [loading]="loading" [disabled]="loginForm.invalid">
      {{ 'action.login' | translate }}
    </app-button>

    <app-button theme="transparent" (click)="showRequestPasswordReset = true">
      {{ 'password.forgot' | translate }}?
    </app-button>
  </div>
</div>

<app-request-password-reset
  *ngIf="showRequestPasswordReset"
  (back)="showRequestPasswordReset = false"
></app-request-password-reset>

<app-organization-selector
  *ngIf="showOrganizationSelector"
  [cannotClose]="true"
  [organizations]="organizations"
  [redirectTo]="redirectTo"
></app-organization-selector>
