import { Provider, APP_BOOTSTRAP_LISTENER, ComponentRef } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { environment } from '../../../environments/environment'
import { Environment } from '../enums/global/environment'
import { FacebookPixelService } from '../services/facebook-pixel.service'

export const FACEBOOK_PIXEL_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: FacebookPixelRouterInitializer,
  deps: [FacebookPixelService, Router]
}

export function FacebookPixelRouterInitializer($fpService: FacebookPixelService, $router: Router) {
  return async (c: ComponentRef<any>) => {
    $router.events.subscribe((event) => {
      if (
        event instanceof NavigationEnd &&
        (event.url === '/' ||
          event.url === '/login' ||
          event.url === '/dashboard' ||
          event.url === '/dashboard/membership') &&
        environment.name === Environment.Production
      ) {
        $fpService.load()
      }
    })
  }
}
