<main>
  <nav class="ns-sidebar-main" [class.ns-sidebar-main--collapsed]="!isSidebarExpanded">
    <div class="ns-mobile-header">
      <button mat-icon-button appBlurOnClick (click)="toggleMenu()">
        <mat-icon>{{ isMenuOpen ? 'menu_open' : 'menu' }}</mat-icon>
      </button>
      <h5>{{ activeTab }}</h5>
    </div>

    <div class="ns-navigation" [class.ns-navigation--open]="isMenuOpen">
      <button class="ns-sidebar-toggle-desktop" mat-fab appBlurOnClick (click)="toggleSidebar()">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <div class="ns-navigation__main">
        <ul class="ns-sidebar-list" (click)="toggleMenu(false, $event)">
          <li class="ns-sidebar-list__item ns-logo-wrapper" [class.ns-logo-wrapper--collapsed]="!isSidebarExpanded">
            <div
              class="ns-sidebar-list__item__button"
              [class.ns-logo-clickable]="organizations.length > 1"
              appInteractive
              (click)="organizations.length > 1 && toggleShowOrganizationSelector()"
            >
              <div class="ns-logo-title">
                <div class="ns-logo-img-container">
                  <img
                    [alt]="'img_alt.hoopit_logo' | translate"
                    [src]="organization.club_logo || '../../../assets/img/new/logo-sidebar.svg'"
                  />
                </div>
                <p class="ns-text extra-small">{{ organization.name }}</p>
              </div>
              <mat-icon *ngIf="organizations.length > 1">unfold_more</mat-icon>
            </div>
          </li>

          <li
            class="ns-sidebar-list__item ns-sub-menu-wrapper"
            [class.ns-sidebar-list__item--expanded]="subMenus[SubMenus.Membership]"
          >
            <button
              class="ns-sidebar-list__item__button"
              [class.ns-sidebar-list__item__button--sub-menu-item-selected]="
                isRouteActivated('/dashboard/members') && !subMenus[SubMenus.Membership]
              "
              matRipple
              [matTooltip]="'dashboard.membership_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              appBlurOnClick
              (click)="toggleSubMenu($event, SubMenus.Membership)"
            >
              <div class="ns-icon-wrapper">
                <mat-icon>playlist_add_check</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.membership_label' | translate }}</span>
              <mat-icon class="ns-sub-menu-expander-icon">keyboard_arrow_down</mat-icon>
            </button>

            <app-expand-y *ngIf="subMenus[SubMenus.Membership]" class="ns-sidebar-list--sub-menu">
              <ul>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/membership"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.dashboard' | translate }}</span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/membership/members"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'membership.members' | translate }}</span>
                  </a>
                </li>
                <app-expand-y *ngIf="isTopLevelAdmin && organization.has_nif_integration" class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/membership/imported"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'membership.imported' | translate }}</span>
                  </a>
                </app-expand-y>
              </ul>
            </app-expand-y>
          </li>

          <li
            class="ns-sidebar-list__item ns-sub-menu-wrapper"
            [class.ns-sidebar-list__item--expanded]="subMenus[SubMenus.Events]"
          >
            <button
              class="ns-sidebar-list__item__button"
              [class.ns-sidebar-list__item__button--sub-menu-item-selected]="
                isRouteActivated('/dashboard/events') && !subMenus[SubMenus.Events]
              "
              matRipple
              [matTooltip]="'dashboard.events_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              appBlurOnClick
              (click)="toggleSubMenu($event, SubMenus.Events)"
            >
              <div class="ns-icon-wrapper">
                <ng-container *appFeatureFlag="RemoteConfigKeys.FeatureConflictingEvents">
                  <span *ngIf="badges.conflictingEvents" class="ns-notification-badge"></span>
                </ng-container>
                <mat-icon>calendar_today</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.events_label' | translate }}</span>
              <mat-icon class="ns-sub-menu-expander-icon">keyboard_arrow_down</mat-icon>
            </button>

            <app-expand-y *ngIf="subMenus[SubMenus.Events]" class="ns-sidebar-list--sub-menu">
              <ul>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/events"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.calendar_label' | translate }}</span>
                  </a>
                </li>
                <app-expand-y *appFeatureFlag="RemoteConfigKeys.FeatureConflictingEvents" class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/events/conflicts"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">
                      {{ 'dashboard.conflicting_events' | translate }}
                      <span *ngIf="badges.conflictingEvents" class="ns-badge ns-badge--new">
                        {{ 'dashboard.new' | translate }}
                      </span>
                      <span *ngIf="!badges.conflictingEvents" class="ns-badge ns-badge--beta">
                        {{ 'miscellaneous.beta' | translate }}
                      </span>
                    </span>
                  </a>
                </app-expand-y>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/events/attendance"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'organization.attendance' | translate }}</span>
                  </a>
                </li>
              </ul>
            </app-expand-y>
          </li>

          <li
            class="ns-sidebar-list__item ns-sub-menu-wrapper"
            [class.ns-sidebar-list__item--expanded]="subMenus[SubMenus.Communication]"
          >
            <button
              class="ns-sidebar-list__item__button"
              [class.ns-sidebar-list__item__button--sub-menu-item-selected]="
                isRouteActivated('/dashboard/communication') && !subMenus[SubMenus.Communication]
              "
              matRipple
              [matTooltip]="'dashboard.communication_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              appBlurOnClick
              (click)="toggleSubMenu($event, SubMenus.Communication)"
            >
              <div class="ns-icon-wrapper">
                <span *ngIf="unreadChatMessagesCount$ | async" class="ns-notification-badge"></span>
                <mat-icon>forum</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.communication_label' | translate }}</span>
              <mat-icon class="ns-sub-menu-expander-icon">keyboard_arrow_down</mat-icon>
            </button>

            <app-expand-y *ngIf="subMenus[SubMenus.Communication]" class="ns-sidebar-list--sub-menu">
              <ul>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/communication/posts"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">
                      {{ 'organization.posts' | translate }}
                    </span>
                  </a>
                </li>

                <li *ngIf="isStreamChatFeatureEnabled" class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/communication/chat"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text ns-chat-item">
                      {{ 'organization.chat' | translate }}
                      <span *ngIf="unreadChatMessagesCount$ | async" class="ns-unread-count">{{
                        unreadChatMessagesCount$ | async
                      }}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </app-expand-y>
          </li>

          <li
            class="ns-sidebar-list__item ns-sub-menu-wrapper"
            [class.ns-sidebar-list__item--expanded]="subMenus[SubMenus.Groups]"
          >
            <button
              class="ns-sidebar-list__item__button"
              [class.ns-sidebar-list__item__button--sub-menu-item-selected]="
                isRouteActivated('/dashboard/groups') && !subMenus[SubMenus.Groups]
              "
              matRipple
              [matTooltip]="'dashboard.groups_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              appBlurOnClick
              (click)="toggleSubMenu($event, SubMenus.Groups)"
            >
              <div class="ns-icon-wrapper">
                <span *ngIf="badges.customFields" class="ns-notification-badge"></span>
                <mat-icon>group</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.groups_label' | translate }}</span>
              <mat-icon class="ns-sub-menu-expander-icon">keyboard_arrow_down</mat-icon>
            </button>

            <app-expand-y *ngIf="subMenus[SubMenus.Groups]" class="ns-sidebar-list--sub-menu">
              <ul>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/groups/users"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.users_label' | translate }}</span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/groups/waiting-list"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.waiting_list_label' | translate }}</span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/groups/settings"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">
                      {{ 'dashboard.group_settings_label' | translate }}
                      <span *ngIf="badges.customFields" class="ns-badge ns-badge--new">
                        {{ 'dashboard.new' | translate }}
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </app-expand-y>
          </li>

          <li
            class="ns-sidebar-list__item ns-sub-menu-wrapper"
            [class.ns-sidebar-list__item--expanded]="subMenus[SubMenus.Economy]"
          >
            <button
              class="ns-sidebar-list__item__button"
              [class.ns-sidebar-list__item__button--sub-menu-item-selected]="
                isRouteActivated('/dashboard/economy') && !subMenus[SubMenus.Economy]
              "
              matRipple
              [matTooltip]="'dashboard.economy_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              appBlurOnClick
              (click)="toggleSubMenu($event, SubMenus.Economy)"
            >
              <div class="ns-icon-wrapper">
                <mat-icon>attach_money</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.economy_label' | translate }}</span>
              <mat-icon class="ns-sub-menu-expander-icon">keyboard_arrow_down</mat-icon>
            </button>

            <app-expand-y *ngIf="subMenus[SubMenus.Economy]" class="ns-sidebar-list--sub-menu">
              <ul>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/economy/payments"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.payments' | translate }}</span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/economy/individual-payments"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.individual_payments' | translate }}</span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/economy/dugnads"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">
                      {{ 'dashboard.sales_dugnads' | translate }}
                      <span class="ns-badge ns-badge--beta">{{ 'miscellaneous.beta' | translate }}</span>
                    </span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/economy/payouts"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.payouts' | translate }}</span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/economy/invoices"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.invoices' | translate }}</span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/economy/expense-claims"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">
                      {{ 'dashboard.expense_claims' | translate }}
                      <!-- <span class="ns-badge ns-badge--beta">{{ 'miscellaneous.beta' | translate }}</span> -->
                    </span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/economy/bank-accounts"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.bank_accounts' | translate }}</span>
                  </a>
                </li>
              </ul>
            </app-expand-y>
          </li>

          <app-expand-y
            *ngIf="modules && modules.modules.accounting.display"
            class="ns-sidebar-list__item ns-sub-menu-wrapper"
            [class.ns-sidebar-list__item--expanded]="subMenus[SubMenus.Accounting]"
          >
            <button
              class="ns-sidebar-list__item__button"
              [class.ns-sidebar-list__item__button--sub-menu-item-selected]="
                isRouteActivated('/dashboard/accounting') && !subMenus[SubMenus.Accounting]
              "
              matRipple
              [matTooltip]="'dashboard.accounting_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              appBlurOnClick
              (click)="toggleSubMenu($event, SubMenus.Accounting)"
            >
              <div class="ns-icon-wrapper">
                <mat-icon>account_balance</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.accounting_label' | translate }}</span>
              <mat-icon class="ns-sub-menu-expander-icon">keyboard_arrow_down</mat-icon>
            </button>

            <app-expand-y *ngIf="subMenus[SubMenus.Accounting]" class="ns-sidebar-list--sub-menu">
              <ul>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/accounting"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'dashboard.dashboard' | translate }}</span>
                  </a>
                </li>
              </ul>
            </app-expand-y>
          </app-expand-y>

          <li
            class="ns-sidebar-list__item ns-sub-menu-wrapper"
            [class.ns-sidebar-list__item--expanded]="subMenus[SubMenus.Locations]"
          >
            <button
              class="ns-sidebar-list__item__button"
              [class.ns-sidebar-list__item__button--sub-menu-item-selected]="
                isRouteActivated('/dashboard/locations') && !subMenus[SubMenus.Locations]
              "
              matRipple
              [matTooltip]="'dashboard.locations_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              appBlurOnClick
              (click)="toggleSubMenu($event, SubMenus.Locations)"
            >
              <div class="ns-icon-wrapper">
                <mat-icon>maps_home_work</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.locations_label' | translate }}</span>
              <mat-icon class="ns-sub-menu-expander-icon">keyboard_arrow_down</mat-icon>
            </button>

            <app-expand-y *ngIf="subMenus[SubMenus.Locations]" class="ns-sidebar-list--sub-menu">
              <ul>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/locations"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">{{ 'locations.location_list' | translate }}</span>
                  </a>
                </li>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/locations/integrations"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">
                      {{ 'locations.integrations' | translate }}
                    </span>
                  </a>
                </li>
              </ul>
            </app-expand-y>
          </li>

          <li
            class="ns-sidebar-list__item ns-sub-menu-wrapper"
            [class.ns-sidebar-list__item--expanded]="subMenus[SubMenus.Organization]"
          >
            <button
              class="ns-sidebar-list__item__button"
              [class.ns-sidebar-list__item__button--sub-menu-item-selected]="
                isRouteActivated('/dashboard/organization') && !subMenus[SubMenus.Organization]
              "
              matRipple
              [matTooltip]="'dashboard.organization_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              appBlurOnClick
              (click)="toggleSubMenu($event, SubMenus.Organization)"
            >
              <div class="ns-icon-wrapper">
                <span *ngIf="badges.smsConfig && isTopLevelAdmin" class="ns-notification-badge"></span>
                <mat-icon>apartment</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.organization_label' | translate }}</span>
              <mat-icon class="ns-sub-menu-expander-icon">keyboard_arrow_down</mat-icon>
            </button>

            <app-expand-y *ngIf="subMenus[SubMenus.Organization]" class="ns-sidebar-list--sub-menu">
              <ul>
                <li class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/organization/certificates"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">
                      {{ 'member_list.certificates' | translate }}
                    </span>
                  </a>
                </li>
                <li *ngIf="isTopLevelAdmin" class="ns-sidebar-list__item">
                  <button
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                    (click)="openPaymentProvider()"
                  >
                    <span class="ns-tab-text">{{ 'dashboard.payment_provider' | translate }}</span>
                  </button>
                </li>
                <app-expand-y *ngIf="isTopLevelAdmin" class="ns-sidebar-list__item">
                  <a
                    routerLink="/dashboard/organization/sms"
                    routerLinkActive="ns-sidebar-list__item__button--selected"
                    class="ns-sidebar-list__item__button"
                    matRipple
                    appBlurOnClick
                  >
                    <span class="ns-tab-text">
                      {{ 'dashboard.sms_configuration' | translate }}

                      <span *ngIf="badges.smsConfig" class="ns-badge ns-badge--new">
                        {{ 'dashboard.new' | translate }}
                      </span>
                      <span *ngIf="!badges.smsConfig" class="ns-badge ns-badge--beta">
                        {{ 'miscellaneous.beta' | translate }}
                      </span>
                    </span>
                  </a>
                </app-expand-y>
              </ul>
            </app-expand-y>
          </li>

          <app-expand-y
            *ngIf="(isTopLevelAdmin || isDivisionAdmin) && doShowFotballdata"
            class="ns-sidebar-list__item"
            matRipple
          >
            <a
              routerLink="/dashboard/fotballdata"
              routerLinkActive="ns-sidebar-list__item__button--selected"
              class="ns-sidebar-list__item__button"
              [matTooltip]="'dashboard.fotballdata_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              appBlurOnClick
            >
              <div class="ns-icon-wrapper">
                <mat-icon>data_usage</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.fotballdata_label' | translate }}</span>
            </a>
          </app-expand-y>

          <li class="ns-sidebar-list__item">
            <button
              class="ns-sidebar-list__item__button"
              [matTooltip]="'dashboard.productboard_label' | translate"
              [matTooltipDisabled]="isSidebarExpanded"
              matTooltipClass="ns-sidebar-tooltip ns-dark"
              matTooltipPosition="right"
              matRipple
              appBlurOnClick
              (click)="openProductboard()"
            >
              <div class="ns-icon-wrapper">
                <mat-icon>poll</mat-icon>
              </div>
              <span class="ns-tab-text">{{ 'dashboard.productboard_label' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>

      <div class="ns-navigation__bottom">
        <ul class="ns-sidebar-list">
          <li class="ns-sidebar-list__item ns-sidebar-toggle-wrapper">
            <button
              class="ns-sidebar-list__item__button ns-sidebar-toggle"
              matRipple
              appBlurOnClick
              (click)="toggleSidebar()"
            >
              <div class="ns-icon-wrapper">
                <mat-icon>chevron_left</mat-icon>
              </div>
            </button>
          </li>
          <li class="ns-sidebar-list__item">
            <button class="ns-sidebar-list__item__button" matRipple (click)="helpMenuTrigger.openMenu()">
              <div class="ns-icon-wrapper">
                <mat-icon>help</mat-icon>
              </div>
              <span class="ns-tab-text" #helpMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="helpMenu">
                {{ 'dashboard.help_label' | translate }}
              </span>
            </button>

            <mat-menu class="ns-dashboard-menu" #helpMenu="matMenu">
              <button mat-menu-item (click)="openIntercom()">{{ 'dashboard.contact_support' | translate }}</button>

              <a mat-menu-item href="https://support.hoopit.io/" target="_blank">
                {{ 'dashboard.help_center' | translate }}
              </a>
            </mat-menu>
          </li>
          <li class="ns-sidebar-list__item ns-dashboard-menu-wrapper">
            <button class="ns-sidebar-list__item__button" matRipple appBlurOnClick (click)="userMenuTrigger.openMenu()">
              <app-profile-picture [user]="user"></app-profile-picture>
              <div class="ns-tab-text-wrapper" #userMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu">
                <span class="ns-tab-text">
                  {{ user?.first_name }}
                </span>
              </div>
            </button>

            <mat-menu class="ns-dashboard-menu" #userMenu="matMenu">
              <button mat-menu-item (click)="openUserProfile()">{{ 'dashboard.my_profile' | translate }}</button>
              <button mat-menu-item (click)="organizations.length > 1 && toggleShowOrganizationSelector()">
                {{ 'dashboard.organization' | translate }}
                <span class="ns-value">{{ organization?.name }}</span>
              </button>

              <button mat-menu-item [matMenuTriggerFor]="languages">
                {{ 'dashboard.language' | translate }}
                <span class="ns-value ns-country-flag-wrapper">
                  <mat-icon aria-label="Country flag" class="ns-country-flag" [ngClass]="currentLanguage"></mat-icon>
                  {{ currentLanguage | uppercase }}
                </span>
              </button>

              <button mat-menu-item (click)="logout()">{{ 'dashboard.logout_label' | translate }}</button>

              <p class="version ns-text extra-small">
                {{ buildVersion }}.{{ buildNumber }}
                @if (environmentName !== Environment.Local) {
                  - {{ buildDate | date: DateFormat.shortDateTime }}
                }
              </p>
            </mat-menu>

            <mat-menu #languages="matMenu" yPosition="above">
              <button
                *ngFor="let language of availableLanguages"
                mat-menu-item
                (click)="changeLanguage($event, language)"
              >
                <mat-icon aria-label="Country flag" class="ns-country-flag" [ngClass]="language"></mat-icon>
                <!-- TODO: consider showing text only in respective language -->
                {{ language | languageName : currentLanguage | async }}
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="ns-dashboard">
    <router-outlet></router-outlet>
  </div>
</main>

<app-user-profile *ngIf="isUserProfileOpen"></app-user-profile>

<app-organization-selector
  *ngIf="showOrganizationSelector"
  [organizations]="organizations"
  (close)="toggleShowOrganizationSelector()"
></app-organization-selector>
