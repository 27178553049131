import { Injectable } from '@angular/core'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { AppCheck, AppCheckTokenResult, getToken, initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AppCheckService {
  app: FirebaseApp
  appCheck: AppCheck

  constructor() {
    this.app = initializeApp(environment.firebaseConfig)

    this.appCheck = initializeAppCheck(this.app, {
      provider: new ReCaptchaV3Provider(environment.reCaptchaApiKey),
      isTokenAutoRefreshEnabled: true
    })
  }

  async getToken(): Promise<AppCheckTokenResult | undefined> {
    let appCheckTokenResponse
    try {
      appCheckTokenResponse = await getToken(this.appCheck)
    } catch (err) {
      // TODO: Handle any errors if the token was not retrieved.
      return
    }
    return appCheckTokenResponse
  }
}
