import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppCheckTokenResult } from '@firebase/app-check'
import { from, Observable } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'

import { AppCheckService } from '../services/app-check.service'

@Injectable()
export class AppCheckInterceptor implements HttpInterceptor {
  constructor(private readonly appCheckService: AppCheckService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.appCheckService.getToken()).pipe(
      take(1),
      switchMap((token: AppCheckTokenResult | undefined) => {
        if (token) {
          request = request.clone({ setHeaders: { 'X-Firebase-AppCheck': token?.token } })
        }
        return next.handle(request)
      })
    )
  }
}
