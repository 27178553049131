import { Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

@Injectable({ providedIn: 'root' })
export class IconRegistryService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  registerIcons(): void {
    this.matIconRegistry
      .addSvgIcon('no-email', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-icons/no-email.svg'))
      .addSvgIcon('loader', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-icons/loader.svg'))
  }
}
