<div class="ns-logo-wrapper">
  <img src="/assets/img/new/logo-sidebar.svg" alt="Hoopit logo">
</div>
<div class="ns-container">
  <div class="ns-text-wrapper">
    <h1>{{ 'no_content.ooops' | translate }}</h1>
    <h3>{{ 'no_content.page_doesnt_exist' | translate }}</h3>
    <p class="ns-text">{{ 'no_content.page_doesnt_exist_description' | translate }}</p>
    <a routerLink="/dashboard" class="ns-button ns-button--primary">{{ 'no_content.back_to_homepage' | translate }}</a>
  </div>

  <img src="/assets/img/empty-states/404.svg" alt="Nothing found" class="ns-404-img">
</div>
