import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { Constants } from '../../constants/constants'
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service'

interface ErrorResponse extends HttpErrorResponse {
  error: {
    code?: string
    localized_message?: string
    data: { detail: string } & string[] & { id: string; error: string }[]
  }
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private translate: TranslateService,
    private snackbarService: SnackbarService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: ErrorResponse) => {
        let errorMessage = this.translate.instant('toaster.something_went_wrong')
        let shouldShowSnackbar = true

        switch (response.status) {
          case 400:
          case 401:
            if (
              // To avoid error duplication, this is handled individually by components that may receive this error.
              response.error.code === Constants.organizationDoesntSupportGroupTypeError ||
              response.error.code === 'sms__not_enough_credits'
            ) {
              shouldShowSnackbar = false
            }

            if (response.error.localized_message) {
              errorMessage = response.error.localized_message
            } else if (response.error.data.detail) {
              errorMessage = response.error.data.detail
            } else {
              if (Array.isArray(response.error.data)) {
                if (typeof response.error.data[0] === 'string') {
                  errorMessage = response.error.data.join('<br>')
                } else {
                  errorMessage = response.error.data
                    .map((fieldError: { id: string; error: string }) => `${fieldError.id}: ${fieldError.error}`)
                    .join('<br>')
                }
                this.snackbarService.error(errorMessage, { persistent: true })
              } else {
                Object.keys(response.error.data).forEach((errorKey) => {
                  errorMessage = ''
                  response.error.data[errorKey].forEach((errorText: string) => (errorMessage += `${errorText}\n`))
                  this.snackbarService.error(errorMessage)
                })
              }
              shouldShowSnackbar = false
            }
            break
          case 403:
            errorMessage = this.translate.instant('toaster.no_access_to_perform_action')
            break
          case 404:
            errorMessage = this.translate.instant('toaster.404')
            if (response.error.code === Constants.invalidPageError || request.url.includes('/users/by_phone/')) {
              shouldShowSnackbar = false
            }
            break
        }

        if (shouldShowSnackbar) {
          this.snackbarService.error(errorMessage)
        }

        return throwError(() => response)
      })
    )
  }
}
