import { HttpClient } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

import en from '../../../assets/i18n/en.json'
import nb from '../../../assets/i18n/nb.json'

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    httpClient,
    './assets/i18n/',
    '.json?v=' + hashCode(`${JSON.stringify(en)}${JSON.stringify(nb)}`)
  )
}

const hashCode = (string: string) => {
  let hash = 0
  let i
  let chr

  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }

  return hash
}
