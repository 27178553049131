import { TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core'
import { HttpLoaderFactory } from '../core/factories/httpLoaderFactory'
import { HttpClient } from '@angular/common/http'

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
}
