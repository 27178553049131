import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service'

@Injectable()
export class RequestVersionInterceptor implements HttpInterceptor {
  constructor(private snackbarService: SnackbarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.shouldHandleRequest()) return next.handle(request)

    return next.handle(request).pipe(
      tap((httpEvent: HttpEvent<any>) => {
        if (httpEvent instanceof HttpResponse) {
          const deprecatedVersionHeader = httpEvent.headers.get('Deprecated-Version')
          if (deprecatedVersionHeader) {
            const warnMessage = `[${request.method}] ${request.url} ${deprecatedVersionHeader}`
            this.pushWarnMessage(warnMessage)
          }
        }
      })
    )
  }

  private shouldHandleRequest(): boolean {
    return !environment.production
  }

  private pushWarnMessage(message: string): void {
    this.snackbarService.warning(message)
  }
}
