export enum SubMenus {
  Membership = 'membership',
  Events = 'events',
  Communication = 'communication',
  Groups = 'groups',
  Economy = 'economy',
  Accounting = 'accounting',
  Locations = 'locations',
  Organization = 'organization'
}
