import { Injectable, NgZone } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable, from } from 'rxjs'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { User } from 'firebase/auth'
import { mergeMap } from 'rxjs/operators'

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private angularFireAuth: AngularFireAuth,
    private ngZone: NgZone
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('Authorization') || request.url.includes('mockapi.io')) return next.handle(request)

    return from(
      new Promise((resolve) => {
        this.angularFireAuth.onAuthStateChanged((user) => {
          resolve(user)
        })
      })
    ).pipe(
      mergeMap((user: User) => {
        if (user) {
          return from(
            this.ngZone.run(() => {
              return user.getIdToken()
            })
          ).pipe(
            mergeMap((token) => {
              request = request.clone({
                setHeaders: {
                  Authorization: `JWT ${token}`
                }
              })

              return next.handle(request)
            })
          )
        } else {
          return next.handle(request)
        }
      })
    )
  }
}
